<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.c">
			<template #title>Podcast Awards - Categories</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'PodcastAwardCategoriesNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>
			<v-data-table :headers="headers" :items="categories" item-key="id" :search="search" @click:row="edit" :item-class="() => 'hover-pointer'">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {call, sync} from "vuex-pathify";
import Models from "../../models";

export default {
	name: "PodcastAwardCategoriesIndex",
	computed:{
		categories: sync('podcast_award_categories/podcast_award_categories')
	},
	data: () => ({
		search: '',
		headers:[
			{
				text: 'Name',
				align: 'start',
				sortable: true,
				value: 'name',
			},
		]
	}),
	beforeMount(){
		this.getCategories(null)
	},
	methods:{
		getCategories: call('podcast_award_categories/getPodcastAwardCategories'),
		edit(data){
			this.$router.push({name:'PodcastAwardCategoriesEdit', params: {id: data.id}})
		},
	}
}
</script>